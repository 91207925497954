<script setup>
import allImages from '../assets/imagegriddata.json'
</script>

<script>

const timeoutDuration = 2400;

const backgroundColors = [
  "#ACC7D0", // teal
  "#E8D99B", // yellow
  "#EDC0B2", // pink
  "#C3C4ED", // purple
  "transparent"
]

export default {
  name: "IntroImageGrid",
  inject: ['isDesktop'],
  props: {
    },
  data() {
    return {
        isPlaying: false,
        timer: null,
        gridWidth: 2,
        gridHeight: 4,
        allImages,
        layoutables: []
    }
  },
  methods: {
    resetGrid() {
        this.layoutables = [];
       // let imageIndicies = Array.from(Array(this.allImages.length).keys());
        for(let x = 0; x < this.gridWidth; ++x) {
            for(let y = 0; y < this.gridHeight; ++y) {

              const randomIndex = Math.floor(Math.random() * backgroundColors.length);

              const layoutable = {
                "backgroundColor": backgroundColors[randomIndex],
                w: 1,
                h: 1,
                visible: true
              }

              if(layoutable.backgroundColor !== 'transparent') {
                if(Math.random() < 0.8) {
                  const randomImage = this.selectUnusedImage();
                  if(randomImage) {
                    layoutable.src = randomImage.src;
                  }
                }
              }

              this.layoutables.push(layoutable);
            }
        }
    },
    selectUnusedImage() {
      let imageIndicies = Array.from(Array(this.allImages.length).keys());
        imageIndicies = imageIndicies.filter((imgIndex) => {
            let result = this.layoutables.find(layoutable => layoutable.src === this.allImages[imgIndex].src);
            return !result;
        });
        let imgIndexIndex = Math.floor(Math.random() * imageIndicies.length -1);
        return this.allImages[imageIndicies.splice(imgIndexIndex, 1)[0]];
    },
    updateGrid() {
        const numToReplace = 2 + Math.round(Math.random() * 3.0);
        let gridTileIndicies = Array.from(Array(this.gridWidth * this.gridHeight).keys());

        for(let i = 0; i < numToReplace; ++i) {
            let gridIndexIndex = Math.floor(Math.random() * gridTileIndicies.length -1);
            let gridIndex = gridTileIndicies.splice(gridIndexIndex, 1)[0];

            const colorIndex = Math.floor(Math.random() * backgroundColors.length);

            this.layoutables[gridIndex].backgroundColor = backgroundColors[colorIndex];
            this.layoutables[gridIndex].src = null;

            if(this.layoutables[gridIndex].backgroundColor !== 'transparent') {
                if(Math.random() < 0.8) {
                  const randomImage = this.selectUnusedImage();
                  if(randomImage) {
                    this.layoutables[gridIndex].src = randomImage.src;
                  }
                  
                }
              }
        }

        this.timer = setTimeout(this.updateGrid, timeoutDuration);
    },
    startAnimation() {
      if(this.isPlaying === false) {
        console.log({
          'isDektop': this.isDesktop
        })
        if(this.isDesktop ) {
            this.gridWidth = 10;
            this.gridHeight = 2;
        } else {
            this.gridWidth = 3;
            this.gridHeight = 2;
        }

        this.resetGrid();        

        if(this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(this.updateGrid, timeoutDuration);
        this.isPlaying = true;
      }
    },
    stopAnimation() {
      if(this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.isPlaying = false;
    },
    gridElementStyle(layoutable) {
      return {
        display: layoutable.visible ? 'block' : 'none',
        backgroundColor: layoutable.backgroundColor,
        gridColumnEnd: 'span ' + layoutable.w,
        gridRowEnd: 'span ' + layoutable.h
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startAnimation();
    })
  },
  beforeUnmount() {
    this.stopAnimation();
  },
  computed: {
    cssProps() {
      return {
        '--grid-columns': this.gridWidth,
        '--grid-rows': this.gridHeight
      }
    }
  }

}
</script>

<template>
    <div class="grid" :style="cssProps">
        <div v-for="(item, index) in layoutables" :key="item.src" class="gridcell" :style="gridElementStyle(item, index)" >
          <img :src="item.src" class="img" v-if="item.src" />
        </div>
        <div id="preload">
          <img v-for="image in allImages" :key="image.src" :src="image.src" />
        </div>
    </div>
</template>

<style scoped>
#preload {
  display: none;
}

.gridcell {
  border-radius: var(--card-corner-radius);
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.gridcell:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: 100%;
}

.gridcell .img {
  width: 100%;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  mix-blend-mode: multiply;
}
  

.grid {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-template-rows: repeat(var(--grid-rows), 1fr);
  
}    
</style>
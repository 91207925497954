<template>
  <div class="main">
    <div class="top">
    <h1>Topp is now part of Manyone</h1>
    <p>
      Don&#39;t hesitate to reach out—we're still the same dedicated team, but now with greater ability to scale and offer a broader range of services.
    </p>
    <p>
      Contact our team lead, Anna: 
      <a href="mailto:anna.borg@manyone.com" >anna.borg@manyone.com</a>.
    </p>
    </div>
    <div class="grid">
      <IntroImageGrid></IntroImageGrid>
    </div>
    
  </div>
</template>

<script>
import IntroImageGrid from './IntroImageGrid.vue'

export default {
  name: 'LandingPage',
  components: {
    IntroImageGrid
  },
  props: {
  }
}
</script>

<style scoped>
.main {
  overflow-x: hidden;
}

.top {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--page-padding-top) var(--page-padding-left);
}

.top h1 {
  max-width: 650px;
}

.top p {
  margin-left: 80px;
  max-width: 350px;
}

@media (max-width: 767px) {
  .top {
    flex-direction: column;
    
  }

  .top p {
    margin-left: 0;
  }
}
</style>

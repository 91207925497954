<template>
  <LandingPage></LandingPage>
  <IntroImageGrid></IntroImageGrid>
</template>

<script>
import { computed } from 'vue'
import LandingPage from './components/LandingPage.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  },
  data() {
    return {
        isDesktop: true
    }
  },
  methods: {
    onResize() {
      console.log('window.innerWidth', window.innerWidth);
        if(window.innerWidth < 767) {
          console.log('isDektop changed to false')
            this.isDesktop = false;
        } else {
          console.log('isDektop changed to true')
            this.isDesktop = true;
        }
    },
    onNewSectionIndex(sectionIndex) {
        this.startSectionIndex = sectionIndex;
    }

  }, 
  provide() {
    return {
      isDesktop: computed(() => this.isDesktop ),
      isTouchDevice: computed(() => this.isTouchDevice)
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
 
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-width: 100%;
  width: 100%;
}
</style>
